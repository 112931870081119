/**
 * @fileoverview gRPC-Web generated client stub for stats
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: stats.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')

var review_pb = require('./review_pb.js')

var topic_pb = require('./topic_pb.js')

var card_pb = require('./card_pb.js')
const proto = {};
proto.stats = require('./stats_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stats.StatsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stats.StatsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stats.GetGeneralStatsRequest,
 *   !proto.stats.GetGeneralStatsResponse>}
 */
const methodDescriptor_StatsService_getGeneralStats = new grpc.web.MethodDescriptor(
  '/stats.StatsService/getGeneralStats',
  grpc.web.MethodType.UNARY,
  proto.stats.GetGeneralStatsRequest,
  proto.stats.GetGeneralStatsResponse,
  /**
   * @param {!proto.stats.GetGeneralStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stats.GetGeneralStatsResponse.deserializeBinary
);


/**
 * @param {!proto.stats.GetGeneralStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stats.GetGeneralStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stats.GetGeneralStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stats.StatsServiceClient.prototype.getGeneralStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stats.StatsService/getGeneralStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getGeneralStats,
      callback);
};


/**
 * @param {!proto.stats.GetGeneralStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stats.GetGeneralStatsResponse>}
 *     Promise that resolves to the response
 */
proto.stats.StatsServicePromiseClient.prototype.getGeneralStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stats.StatsService/getGeneralStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getGeneralStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stats.GetReviewGeneralStatsRequest,
 *   !proto.stats.GetReviewGeneralStatsReponse>}
 */
const methodDescriptor_StatsService_getReviewGeneralStats = new grpc.web.MethodDescriptor(
  '/stats.StatsService/getReviewGeneralStats',
  grpc.web.MethodType.UNARY,
  proto.stats.GetReviewGeneralStatsRequest,
  proto.stats.GetReviewGeneralStatsReponse,
  /**
   * @param {!proto.stats.GetReviewGeneralStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stats.GetReviewGeneralStatsReponse.deserializeBinary
);


/**
 * @param {!proto.stats.GetReviewGeneralStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stats.GetReviewGeneralStatsReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stats.GetReviewGeneralStatsReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stats.StatsServiceClient.prototype.getReviewGeneralStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stats.StatsService/getReviewGeneralStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getReviewGeneralStats,
      callback);
};


/**
 * @param {!proto.stats.GetReviewGeneralStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stats.GetReviewGeneralStatsReponse>}
 *     Promise that resolves to the response
 */
proto.stats.StatsServicePromiseClient.prototype.getReviewGeneralStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stats.StatsService/getReviewGeneralStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getReviewGeneralStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stats.GetTopicStatRequest,
 *   !proto.stats.GetTopicStatResponse>}
 */
const methodDescriptor_StatsService_getTopicStats = new grpc.web.MethodDescriptor(
  '/stats.StatsService/getTopicStats',
  grpc.web.MethodType.UNARY,
  proto.stats.GetTopicStatRequest,
  proto.stats.GetTopicStatResponse,
  /**
   * @param {!proto.stats.GetTopicStatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stats.GetTopicStatResponse.deserializeBinary
);


/**
 * @param {!proto.stats.GetTopicStatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stats.GetTopicStatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stats.GetTopicStatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stats.StatsServiceClient.prototype.getTopicStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stats.StatsService/getTopicStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getTopicStats,
      callback);
};


/**
 * @param {!proto.stats.GetTopicStatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stats.GetTopicStatResponse>}
 *     Promise that resolves to the response
 */
proto.stats.StatsServicePromiseClient.prototype.getTopicStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stats.StatsService/getTopicStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getTopicStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stats.GetTopicGlobalStatsRequest,
 *   !proto.stats.GetTopicGlobalStatsResponse>}
 */
const methodDescriptor_StatsService_getTopicGlobalStats = new grpc.web.MethodDescriptor(
  '/stats.StatsService/getTopicGlobalStats',
  grpc.web.MethodType.UNARY,
  proto.stats.GetTopicGlobalStatsRequest,
  proto.stats.GetTopicGlobalStatsResponse,
  /**
   * @param {!proto.stats.GetTopicGlobalStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stats.GetTopicGlobalStatsResponse.deserializeBinary
);


/**
 * @param {!proto.stats.GetTopicGlobalStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stats.GetTopicGlobalStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stats.GetTopicGlobalStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stats.StatsServiceClient.prototype.getTopicGlobalStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stats.StatsService/getTopicGlobalStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getTopicGlobalStats,
      callback);
};


/**
 * @param {!proto.stats.GetTopicGlobalStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stats.GetTopicGlobalStatsResponse>}
 *     Promise that resolves to the response
 */
proto.stats.StatsServicePromiseClient.prototype.getTopicGlobalStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stats.StatsService/getTopicGlobalStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getTopicGlobalStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stats.GetReviewManualStatsRequest,
 *   !proto.stats.GetReviewManualStatsResponse>}
 */
const methodDescriptor_StatsService_getReviewManualStats = new grpc.web.MethodDescriptor(
  '/stats.StatsService/getReviewManualStats',
  grpc.web.MethodType.UNARY,
  proto.stats.GetReviewManualStatsRequest,
  proto.stats.GetReviewManualStatsResponse,
  /**
   * @param {!proto.stats.GetReviewManualStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stats.GetReviewManualStatsResponse.deserializeBinary
);


/**
 * @param {!proto.stats.GetReviewManualStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stats.GetReviewManualStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stats.GetReviewManualStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stats.StatsServiceClient.prototype.getReviewManualStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stats.StatsService/getReviewManualStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getReviewManualStats,
      callback);
};


/**
 * @param {!proto.stats.GetReviewManualStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stats.GetReviewManualStatsResponse>}
 *     Promise that resolves to the response
 */
proto.stats.StatsServicePromiseClient.prototype.getReviewManualStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stats.StatsService/getReviewManualStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getReviewManualStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stats.GetReviewSM2StatsRequest,
 *   !proto.stats.GetReviewSM2StatsResponse>}
 */
const methodDescriptor_StatsService_getReviewSM2Stats = new grpc.web.MethodDescriptor(
  '/stats.StatsService/getReviewSM2Stats',
  grpc.web.MethodType.UNARY,
  proto.stats.GetReviewSM2StatsRequest,
  proto.stats.GetReviewSM2StatsResponse,
  /**
   * @param {!proto.stats.GetReviewSM2StatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stats.GetReviewSM2StatsResponse.deserializeBinary
);


/**
 * @param {!proto.stats.GetReviewSM2StatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stats.GetReviewSM2StatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stats.GetReviewSM2StatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stats.StatsServiceClient.prototype.getReviewSM2Stats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stats.StatsService/getReviewSM2Stats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getReviewSM2Stats,
      callback);
};


/**
 * @param {!proto.stats.GetReviewSM2StatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stats.GetReviewSM2StatsResponse>}
 *     Promise that resolves to the response
 */
proto.stats.StatsServicePromiseClient.prototype.getReviewSM2Stats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stats.StatsService/getReviewSM2Stats',
      request,
      metadata || {},
      methodDescriptor_StatsService_getReviewSM2Stats);
};


module.exports = proto.stats;

