import {IUIError, InternalError, InternalErrorTypes, NewInternalError,} from "../service/cartaError";
import {Topic} from "./topic";
import {TopicRelationshipEnumDTO} from "../proto/topic_pb";
import {IEdge, INode, NodeEdge} from "../components/graph/ForceGraph";
import {RelTopicDS} from "../stores/TopicStore";
import {NodeObject} from "react-force-graph-2d";
import {TreeNode} from "../components/tree/CustomTreeView";
import {Ok, Result} from "../utils/result";

interface NodeAndEdge {
    nodes: INode[];
    edges: IEdge[];
}

export class TopicGraph {
    private _nodes: INode[] = []
    private _edges: IEdge[] = []
    private _topics: Map<string, Topic> = new Map<string, Topic>();
    private _nodeMap: Map<string, INode> = new Map<string, INode>();
    private _relationships: Map<string, TopicRelation[]> = new Map<string, TopicRelation[]>()
    private _islands: Map<string, INode> = new Map<string, INode>();
    
    get islands(): Map<string, INode> {
        return this._islands;
    }
    
    constructor(nodes: INode[], edges: IEdge[], topics: Map<string, Topic>, relationships: Map<string, TopicRelation[]>) {
        this._nodes = nodes;
        this._edges = edges;
        this._topics = topics;
        this._relationships = relationships;
    }
    
    public static new(): TopicGraph {
        return new TopicGraph([], [], new Map<string, Topic>(), new Map<string, TopicRelation[]>())
    }

    public static buildGraphFromTopicIDs(ids: string[], topics: Map<string, Topic>, fullGraph?: Map<string, TopicRelation[]>): TopicGraph | undefined {
        if (!fullGraph) {
            return undefined
        }

        let map: Map<string, TopicRelation[]> = new Map();
        ids.forEach((id) => {
            const dep = this.getDependents(id, fullGraph)
            if (dep) {
                map.set(id, dep)
            }
        })

        let relData = TopicGraph.toTopicRelationshipData(topics, map)

        let x = TopicGraph.new()
        x.fromRelationshipData(relData)

        return x
    }


    public static getDependents(id: string, fullGraph: Map<string, TopicRelation[]>): TopicRelation[] | undefined {
        let relations = fullGraph.get(id);

        return relations
    }

    public static toTopicRelationshipData(topics: Map<string, Topic>, map: Map<string, TopicRelation[]>): TopicRelationshipData {
        let data = new TopicRelationshipData()
        data.graph = map
        data.topics = topics

        return data
    }
    
    public static fromSingleTopic(topic: Topic): TopicGraph {
        let nodes: INode[] = []
        let edges: IEdge[] = []
        let topicMap: Map<string, Topic> = new Map<string, Topic>();
        
        let node: INode = {
            id: topic.id,
            title: topic.topic,
            color: topic.color
        }
        nodes.push(node)
        
        topicMap.set(topic.id, topic);
        
        return new TopicGraph(nodes, edges, topicMap, new Map<string, TopicRelation[]>());
    }
    
    public static fromTopicDS(ds: RelTopicDS): TopicGraph {
        let nodes: INode[] = []
        let edges: IEdge[] = []
        let topicMap: Map<string, Topic> = new Map<string, Topic>();
        
        let map: Map<string, TopicRelation[]> = new Map<string, TopicRelation[]>();
        ds.topicMap.forEach((value, key) => {
            let node: INode = {
                id: value.id,
                title: value.topic,
                color: value.color
            }
            nodes.push(node)
            
            topicMap.set(key, value);
        });
        
        ds.relTopicList.forEach((item) => {
            let edge: IEdge = {
                id: `${item.relTopic.topic1Id}-${item.relTopic.topic2Id}-${item.relTopic.relationship}`,
                value: item.depth,
                source: item.relTopic.topic1Id,
                target: item.relTopic.topic2Id,
                kind: item.relTopic.relationship,
            };
            edges.push(edge)
            
            let relation: TopicRelation = {
                id: item.relTopic.topic2Id,
                relationship: item.relTopic.relationship,
            };
            
            if (map.has(item.relTopic.topic1Id)) {
                let map_item = map.get(item.relTopic.topic1Id)!;
                map_item.push(relation);
                map.set(item.relTopic.topic1Id, map_item);
            } else {
                map.set(item.relTopic.topic1Id, [relation]);
            }
        });
        
        return new TopicGraph(nodes, edges, topicMap, map);
    }
    
    public static fromMultipleTopicDS(ds: RelTopicDS[]): TopicGraph {
        let nodes: INode[] = []
        let edges: IEdge[] = []
        let topicMap: Map<string, Topic> = new Map<string, Topic>();
        
        ds.forEach((x) => {
            x.topicMap.forEach((value, key) => {
                let node: INode = {
                    id: value.id,
                    title: value.topic,
                    color: value.color
                }
                nodes.push(node)
                
                topicMap.set(key, value);
            });
        });
        
        let map: Map<string, TopicRelation[]> = new Map<string, TopicRelation[]>();
        ds.forEach((x) => {
            x.relTopicList.forEach((item) => {
                let edge: IEdge = {
                    id: `${item.relTopic.topic1Id}-${item.relTopic.topic2Id}-${item.relTopic.relationship}`,
                    value: item.depth,
                    source: item.relTopic.topic1Id,
                    target: item.relTopic.topic2Id,
                    kind: item.relTopic.relationship,
                };
                
                let relation: TopicRelation = {
                    id: item.relTopic.topic2Id,
                    relationship: item.relTopic.relationship,
                };
                
                if (map.has(item.relTopic.topic1Id)) {
                    let map_item = map.get(item.relTopic.topic1Id)!;
                    map_item.push(relation);
                    map.set(item.relTopic.topic1Id, map_item);
                } else {
                    map.set(item.relTopic.topic1Id, [relation]);
                }
                
                edges.push(edge)
            });
        });
        
        return new TopicGraph(nodes, edges, topicMap, map);
    }
    
    
    initIslands = (relationships:  Map<string, TopicRelation[]>) => {
        let islands = new Map<string, INode>();
        
        relationships.forEach((value: TopicRelation[], key: string, map: Map<string, TopicRelation[]>) => {
            if (value.length === 0) {
                islands.set(key, this._nodeMap.get(key)!)
            }
        })
        
        this._islands = islands;
    }
    
    initNodeMap = () => {
        this._nodeMap = new Map<string, INode>();
        this._nodes.forEach((node) => {
            this._nodeMap.set(node.id, node);
        })
    }
    
    
    public convertGraphToTreeData = (graph: TopicGraph) => {
        
        
        // // Transform the graph into a hierarchical structure
        // // Adjust this implementation based on the actual structure of your graph data
        // const nodesById = new Map(graph.nodes.map(node => [node.id, { ...node, children: [] }]));
        // graph.edges.forEach(edge => {
        //     if (edge.source == undefined || edge.target == undefined) {
        //         return;
        //     }
        //
        //     const parent = nodesById.get(edge.source!);
        //     const child = nodesById.get(edge.target!);
        //     if (parent && child) {
        //         parent.children.push(child);
        //     }
        // });
        // // Assuming the root nodes are those without any incoming edges
        // const rootNodes = Array.from(nodesById.values()).filter(node => graph.edges.every(edge => edge.target !== node.id));
        // return rootNodes;
    };
    
    public fromRelationshipData = (data: TopicRelationshipData) => {
        let edges: IEdge[] = [];
        data.graph.forEach((targets, source) => {
            targets.forEach((target) => {
                edges.push({
                    id: `${source}-${target.id}-${target.relationship}`,
                    value: 3,
                    source: source,
                    target: target.id,
                    kind: target.relationship,
                });
            });
        });
        
        const topicNodes: Topic[] = Array.from(data.topics.values());
        
        this._nodes = topicNodes.map((x) => convertTopicToNode(x, data.graph.get(x.id)!))
        this._edges = edges
        this._topics = data.topics
        this._relationships = data.graph

        this.initNodeMap();
        this.initIslands(data.graph);
    };
    
    public fromRelationshipDataWithoutIslands = (data: TopicRelationshipData) => {
        let edges: IEdge[] = [];
        data.graph.forEach((targets, source) => {
            targets.forEach((target) => {
                edges.push({
                    id: `${source}-${target.id}-${target.relationship}`,
                    value: 3,
                    source: source,
                    target: target.id,
                    kind: target.relationship,
                });
            });
        });
        
        const topicNodes: Topic[] = Array.from(data.topics.values());
        
        this._nodes = topicNodes.map((x) => convertTopicToNode(x, data.graph.get(x.id)!))
        this._edges = edges
        this._topics = data.topics
        this._relationships = data.graph
        
        this.initNodeMap();
        this.initIslands(data.graph);
    };
    
    findMatchingNodesAndEdges = (
        topicId: string,
        graph: TopicGraph
    ): NodeEdge => {
        let nodes: INode[] = graph._nodes.filter((item) => item.id === topicId);
        let edges: IEdge[] = graph._edges.filter((item) => {
            return item.target == topicId || item.source == topicId;
        });
        
        return {
            edges: edges,
            nodes: nodes,
        };
    };
    
    /**
     * Converts the graph to a tree data structure
     * @param root - Passes in a root to become the top of the tree
     * @param filter - Filters the tree based on the relationship type
     */
    public toTreeNodes(root: string | null, filter: TopicRelationshipEnumDTO): TreeNode[] {
        return TopicGraph.toTreeNodes(this._topics, this._relationships, filter)
    }

    /**
     * Converts the graph to a tree data structure
     * @param root - Passes in a root to become the top of the tree
     * @param filter - Filters the tree based on the relationship type
     */
    public static toTreeNodes(topics:  Map<string, Topic>, relationships: Map<string, TopicRelation[]>, filter: TopicRelationshipEnumDTO): TreeNode[] {
        // Helper map to track if a node has been visited
        const visited = new Map<string, boolean>();

        // Helper function to recursively build tree nodes
        function buildTreeNode(topicId: string): TreeNode | null {
            if (visited.get(topicId)) return null;
            visited.set(topicId, true);

            const topic = topics.get(topicId);
            if (!topic) return null;

            const treeNode: TreeNode = {
                id: topic.id,
                name: topic.topic,
                relationship: filter,
                children: []
            };

            // Check and add children based on relationships and filter
            const relations = relationships.get(topicId);
            if (relations) {
                for (const relation of relations) {
                    if (relation.relationship === filter) {
                        const childNode = buildTreeNode(relation.id);
                        if (childNode) {
                            if (treeNode.children) {
                                treeNode.children.push(childNode);
                            } else {
                                treeNode.children = [childNode];
                            }
                        }
                    }
                }
            }

            // Optionally sort or process children here if needed

            return treeNode;
        }

        // Create the top-level nodes
        const result: TreeNode[] = [];
        for (const [topicId, _] of topics) {
            if (!visited.get(topicId)) {  // Ensure not adding duplicates
                const node = buildTreeNode(topicId);
                if (node) {
                    result.push(node);
                }
            }
        }

        return result;
    }

    static extractTreeNode(relationships: Map<string, TopicRelation[]>, visited: Set<string>, treeNodes: Map<string, TreeNode>, relationship: TopicRelationshipEnumDTO): TreeNode[] {
        let finalNodes: TreeNode[] = [];
        let rootNodeIds = new Set<string>(treeNodes.keys());

        relationships.forEach((relations, parentId) => {
            relations.forEach(relation => {
                const childId = relation.id;

                if (!visited.has(childId) && relation.relationship === relationship) { // Prevent cycles
                    visited.add(childId);

                    const parentNode = treeNodes.get(parentId);
                    const childNode = treeNodes.get(childId);

                    if (parentNode && childNode) {
                        parentNode.children = [...(parentNode.children || []), childNode];
                        rootNodeIds.delete(childId); // This node is a child, not a root
                    }
                }
            });
        });

        // Add only root nodes to finalNodes
        rootNodeIds.forEach(rootId => {
            const rootNode = treeNodes.get(rootId);
            if (rootNode) {
                finalNodes.push(rootNode);
            }
        });

        return finalNodes;
    }

    get nodes(): INode[] {
        return this._nodes;
    }
    
    set nodes(value: INode[]) {
        this._nodes = value;
    }
    
    get edges(): IEdge[] {
        return this._edges;
    }
    
    set edges(value: IEdge[]) {
        this._edges = value;
    }
    
    get topics(): Map<string, Topic> {
        return this._topics;
    }
    
    set topics(value: Map<string, Topic>) {
        this._topics = value;
    }
    
    get relationships(): Map<string, TopicRelation[]> {
        return this._relationships;
    }
    
    set relationships(value: Map<string, TopicRelation[]>) {
        this._relationships = value;
    }
}

/// This represents the topic relationship data struct coming from the backend
export class TopicRelationshipData {
    // This is technically a map of <id, id[]>, the final graph is constructed from this
    graph: Map<string, TopicRelation[]>;
    // This map enables quick lookup from a topic id to a topic.
    topics: Map<string, Topic>;
    
    constructor() {
        this.graph = new Map<string, TopicRelation[]>();
        this.topics = new Map<string, Topic>();
        
        // makeObservable(this, {
        //     // topics: observable,
        //     _graph: observable,
        //     _topics: observable,
        //
        //     renderGraph: computed,
        //
        //     modify: action,
        // })
    }
    
    /**
     * Check to see if there are any cycles in the graph
     */
    public validate(): Result<void, IUIError> {
        const result = this.detectCycleInParentChild();
        
        if (result) {
            throw new Error(`Cycle detected in graph: ${result.join(" -> ")}`);
        }
        
        return Ok(undefined);
    }
    
    public fromParts(mainTopic: Topic, parents: Topic[], children: Topic[], generics: Topic[]) {
        this.topics.set(mainTopic.id, mainTopic);
        
        // Ensure an empty relation array exists for mainTopic
        if (!this.graph.has(mainTopic.id)) {
            this.graph.set(mainTopic.id, []);
        }
        
        // Add parent-child relationships
        parents.forEach((parent) => {
            this.topics.set(parent.id, parent);
            
            if (!this.graph.has(parent.id)) {
                this.graph.set(parent.id, []);
            }
            
            this.graph.get(parent.id)!.push({
                id: mainTopic.id,
                relationship: TopicRelationshipEnumDTO.PARENTCHILD,
            });
        });
        
        // Add child-parent relationships
        children.forEach((child) => {
            this.topics.set(child.id, child);
            
            if (!this.graph.has(mainTopic.id)) {
                this.graph.set(mainTopic.id, []);
            }
            
            this.graph.get(mainTopic.id)!.push({
                id: child.id,
                relationship: TopicRelationshipEnumDTO.PARENTCHILD,
            });
        });
        
        // Add generic relationships
        generics.forEach((generic) => {
            this.topics.set(generic.id, generic);
            
            if (!this.graph.has(mainTopic.id)) {
                this.graph.set(mainTopic.id, []);
            }
            
            this.graph.get(mainTopic.id)!.push({
                id: generic.id,
                relationship: TopicRelationshipEnumDTO.GENERIC,
            });
        });
    }
    
    // Function to detect cycles in PARENTCHILD relationships and return the cycle path
    public detectCycleInParentChild(): string[] | null {
        const visited: Set<string> = new Set();
        const recursionStack: Map<string, string[]> = new Map(); // Map to store the path
        
        // Helper function for depth-first search
        const dfs = (topicId: string, path: string[]): string[] | null => {
            if (!visited.has(topicId)) {
                visited.add(topicId);
                path.push(topicId); // Add topic to the current path
                recursionStack.set(topicId, [...path]); // Store the current path
                
                const relations = this.graph.get(topicId) || [];
                for (const relation of relations) {
                    // Only consider PARENTCHILD relationships
                    if (relation.relationship === TopicRelationshipEnumDTO.PARENTCHILD) {
                        if (!visited.has(relation.id)) {
                            const cyclePath = dfs(relation.id, path);
                            if (cyclePath) {
                                return cyclePath; // Return the path if a cycle is detected
                            }
                        } else if (recursionStack.has(relation.id)) {
                            // Cycle detected, return the cycle path
                            const cycleStartIndex = recursionStack.get(relation.id)?.indexOf(relation.id);
                            return path.slice(cycleStartIndex).concat(relation.id);
                        }
                    }
                }
            }
            
            // Backtrack: remove the topic from the path and recursion stack
            path.pop();
            recursionStack.delete(topicId);
            return null;
        };
        
        // Check for cycles in all topics
        for (const topicId of this.graph.keys()) {
            const cyclePath = dfs(topicId, []);
            if (cyclePath) {
                return cyclePath; // Return the cycle path if a cycle is detected
            }
        }
        
        return null; // No cycles detected
    }
    
    public fromExisting(
        topics: Map<string, Topic>,
        graph: Map<string, TopicRelation[]>
    ) {
        this.topics = topics;
        this.graph = graph;
    }
    
    public fromTopicDS(ds: RelTopicDS) {
        this.topics = ds.topicMap;
        
        let map: Map<string, TopicRelation[]> = new Map<string, TopicRelation[]>();
        ds.relTopicList.forEach((item) => {
            let relation: TopicRelation = {
                id: item.relTopic.topic2Id,
                relationship: item.relTopic.relationship,
            };
            
            if (map.has(item.relTopic.topic1Id)) {
                let map_item = map.get(item.relTopic.topic1Id)!;
                map_item.push(relation);
                map.set(item.relTopic.topic1Id, map_item);
            } else {
                map.set(item.relTopic.topic1Id, [relation]);
            }
        });
        
        this.graph = map;
    }
    
    public fromMultipleTopicDS(ds: RelTopicDS[]) {
        let topicMap: Map<string, Topic> = new Map<string, Topic>();
        ds.forEach((x) => {
            x.topicMap.forEach((value, key) => {
                topicMap.set(key, value);
            });
        });
        this.topics = topicMap;
        
        let map: Map<string, TopicRelation[]> = new Map<string, TopicRelation[]>();
        ds.forEach((x) => {
            x.relTopicList.forEach((item) => {
                let relation: TopicRelation = {
                    id: item.relTopic.topic2Id,
                    relationship: item.relTopic.relationship,
                };
                
                if (map.has(item.relTopic.topic1Id)) {
                    let map_item = map.get(item.relTopic.topic1Id)!;
                    map_item.push(relation);
                    map.set(item.relTopic.topic1Id, map_item);
                } else {
                    map.set(item.relTopic.topic1Id, [relation]);
                }
            });
        });
        
        this.graph = map;
    }

    
    public modify(
        action: RelationshipAction,
        topics?: Topic[],
        relations?: Map<string, TopicRelation[]>
    ) {
        
        switch (action) {
            case RelationshipAction.INSERT || RelationshipAction.UPDATE:
                if (topics) {
                    topics.forEach((topic) => {
                        this.topics.set(topic.id, topic);
                    });
                }
                if (relations) {
                    relations.forEach((v, k) => {
                        this.graph.set(k, v);
                    });
                }
                
                
                break;
            
            case RelationshipAction.DELETE:
                if (topics) {
                    topics.forEach((topic) => {
                        this.topics.delete(topic.id);
                        this.graph.delete(topic.id);
                    });
                }
                break;
        }
    }
}

export enum RelationshipAction {
    INSERT,
    UPDATE,
    DELETE,
}

export interface TopicRelation {
    id: string;
    relationship: TopicRelationshipEnumDTO;
}

/**
 * Performs a conversion from the DTO type to a Node type that is lightweight and be displayed on a ForceGraph
 * @param topic
 * @param relations
 */
export const convertTopicToNode = (topic: Topic, relations: TopicRelation[]): INode => {
    return {
        title: topic.topic,
        color: topic.color,
        id: topic.id,
        depth: relations ? relations.length : 0
    };
};

const setupNodes = (topics: Topic[]): Map<string, Topic> => {
    let map: Map<string, Topic> = new Map();
    topics.forEach((topic) => {
        map.set(topic.id, topic);
    });
    
    return map;
};

// export interface TopicGraphData {
//   nodes: INode[];
//   edges: IEdge[];
//   topics: Map<string, Topic>;
//   relationships: Map<string, TopicRelation[]>;
// }


// export const constructGraph = (data: TopicRelationshipData): TopicGraph => {
//   let edges: IEdge[] = [];
//   data.graph.forEach((targets, source) => {
//     targets.forEach((target) => {
//       edges.push({
//         id: `${source}-${target.id}-${target.relationship}`,
//         value: 3,
//         source: source,
//         target: target.id,
//         kind: target.relationship,
//       });
//     });
//   });
//
//   const topicNodes: Topic[] = Array.from(data.topics.values());
//
//
//   return {
//     nodes: topicNodes.map(convertTopicToNode),
//     edges: edges,
//     topics: data.topics,
//     relationships: data.graph,
//   };
// };

export const getChildren = (
    topics: TopicGraph,
    id: string
): Topic[] | InternalError => {
    let children: Topic[] = [];
    
    let childIds = topics.relationships.get(id);
    
    if (childIds) {
        childIds.forEach((relation) => {
            if (relation.relationship === TopicRelationshipEnumDTO.PARENTCHILD) {
                const child = topics.topics.get(relation.id);
                if (child) {
                    children.push(child);
                }
            }
        });
        
        return children;
    }
    
    return NewInternalError(
        "getChildren",
        InternalErrorTypes.LocalNotFound,
        `attempted to get children of topic id: ${id} but id was not found in collection`
    );
};

const convertNodeObjectToINode = (node: NodeObject): INode => {
    return {
        // payload: Topic,
        // color?: string
        id: node.id,
        x: node.x,
        y: node.y,
        vx: node.vx,
        vy: node.vy,
        fx: node.fx,
        fy: node.fy,
    } as INode;
};

const convertINodeToNodeObject = (node: INode): NodeObject => {
    return {
        x: node.x,
        y: node.y,
        vx: node.vx,
        vy: node.vy,
        fx: node.fx,
        fy: node.fy,
    } as NodeObject;
};

// export const getParents = (topics: TopicGraphData, id: string): Topic[] | InternalError => {
//     let children: Topic[] = [];
//
//     let childIds = topics.relationships.get(id)
//     if (childIds) {
//         childIds.forEach((id) => {
//             const child = topics.topics.get(id)
//             if (child) {
//                 children.push(child)
//             }
//         })
//
//         return children
//     }
//
//
//     return NewInternalError("getChildren", InternalErrorTypes.LocalNotFound, `attempted to get children of topic id: ${id} but id was not found in collection`)
// }

// export const GRAPH: TopicGraphData = constructGraph(data)
